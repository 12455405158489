<i18n lang="json">
{
  "ru": {
    "Бизнесу": "Бизнесу",
    "Частным клиентам": "Частным клиентам",
    "О банке": "О банке",
    "Переводы": "Переводы",
    "Карты": "Карты",
    "Эквайринг": "Эквайринг",
    "Зарплатный проект": "Зарплатный проект",
    "Кредитование": "Кредитование",
    "Вклады": "Вклады",
    "АПСАРЫ": "Апсары",
    "Онлайн-банк": "Онлайн-банк",
    "Офисы и банкоматы": "Офисы и банкоматы",
    "Новости и статьи": "Новости и статьи",
    "Отправить": "Отправить",
    "Задать вопрос": "Задать вопрос",
    "Кошелек": "Кошелек",
    "Пополнение": "Пополнение \"Онлайн 24\"",
    "Вакансии": "Вакансии",
    "Сейфовые ячейки": "Сейфовые ячейки",
    "Вход в онлайн-банк": "Вход в онлайн-банк",
    "Чат-поддержка": "Чат-поддержка"
  },
  "en": {
    "Бизнесу": "For Business",
    "Частным клиентам": "Private Clients",
    "О банке": "About the Bank",
    "Переводы": "Transfers",
    "Карты": "Cards",
    "Эквайринг": "Acquiring",
    "Зарплатный проект": "Payroll Project",
    "Кредитование": "Lending",
    "Вклады": "Deposits",
    "АПСАРЫ": "APSARA",
    "Онлайн-банк": "Online Banking",
    "Офисы и банкоматы": "Offices and ATMs",
    "Новости и статьи": "News and Articles",
    "Отправить": "Send",
    "Задать вопрос": "Ask a Question",
    "Кошелек": "Wallet",
    "Пополнение": "Top Up \"Online 24\"",
    "Вакансии": "Vacancies",
    "Сейфовые ячейки": "Safe Deposit Boxes",
    "Вход в онлайн-банк": "Online Banking Login",
    "Чат-поддержка": "Chat Support"
  },
  "tr": {
    "Бизнесу": "İŞ",
    "Частным клиентам": "Bireysel Müşteriler",
    "О банке": "Bank Hakkında",
    "Переводы": "Havale",
    "Карты": "Kartlar",
    "Эквайринг": "Satış Ortaklığı",
    "Зарплатный проект": "Maaş Projesi",
    "Кредитование": "Kredi",
    "Вклады": "Yatırımlar",
    "АПСАРЫ": "APSARA",
    "Онлайн-банк": "Çevrimiçi Bankacılık",
    "Офисы и банкоматы": "Ofisler ve ATM'ler",
    "Новости и статьи": "Haberler ve Makaleler",
    "Отправить": "Gönder",
    "Задать вопрос": "Soru Sormak",
    "Кошелек": "Cüzdan",
    "Пополнение": "Online 24\" Yükleme",
    "Вакансии": "İş İlanları",
    "Сейфовые ячейки": "Kasa Kafesleri",
    "Вход в онлайн-банк": "Çevrimiçi Bankacılık Girişi",
    "Чат-поддержка": "Sohbet Desteği"
  },
  "es": {
    "Бизнесу": "Para Negocios",
    "Частным клиентам": "Clientes Privados",
    "О банке": "Sobre el Banco",
    "Переводы": "Transferencias",
    "Карты": "Tarjetas",
    "Эквайринг": "Adquirir",
    "Зарплатный проект": "Proyecto de Nómina",
    "Кредитование": "Créditos",
    "Вклады": "Depósitos",
    "АПСАРЫ": "APSARA",
    "Онлайн-банк": "Banca en Línea",
    "Офисы и банкоматы": "Oficinas y Cajeros Automáticos",
    "Новости и статьи": "Noticias y Artículos",
    "Отправить": "Enviar",
    "Задать вопрос": "Hacer una Pregunta",
    "Кошелек": "Cartera",
    "Пополнение": "Recarga \"Online 24\"",
    "Вакансии": "Vacantes",
    "Сейфовые ячейки": "Cajas de Seguridad",
    "Вход в онлайн-банк": "Acceso a la Banca en Línea",
    "Чат-поддержка": "Soporte por Chat"
  },
  "ar": {
    "Бизнесу": "أعمال",
    "Частным клиентам": "عملاء خاصين",
    "О банке": "عن البنك",
    "Переводы": "تحويلات",
    "Карты": "بطاقات",
    "Эквайринг": "الاكتساب",
    "Зарплатный проект": "مشروع الرواتب",
    "Кредитование": "الائتمان",
    "Вклады": "ودائع",
    "АПСАРЫ": "أبسارا",
    "Онлайн-банк": "الخدمات المصرفية عبر الإنترنت",
    "Офисы и банкоматы": "المكاتب وأجهزة الصراف الآلي",
    "Новости и статьи": "الأخبار والمقالات",
    "Отправить": "إرسال",
    "Задать вопрос": "طرح سؤال",
    "Кошелек": "محفظة",
    "Пополнение": "إعادة تعبئة \"أونلاين 24\"",
    "Вакансии": "وظائف",
    "Сейфовые ячейки": "صناديق الأمانات",
    "Вход в онлайн-банк": "تسجيل الدخول إلى الخدمات المصرفية عبر الإنترنت",
    "Чат-поддержка": "دعم الدردشة"
  }
}
</i18n>
<script setup>
import { ref, onMounted } from "vue";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import ABankChat from "./ABankChat.vue";
import AInformation from "./AInformation.vue";
import { helpers } from "@vuelidate/validators";

const route = useRoute();
const showHelp = ref(false);
const { t, locale } = useI18n({ useScope: "local" });
const headerVisible = ref(true);
const businessActive = ref(false);
const { width, height } = useWindowSize();

const isActive = (path) => {
  return route.path === path;
};

const toggleHelp = () => {
  showHelp.value = !showHelp.value;
};

const handleScroll = () => {
  if (width.value > 1024) {
    headerVisible.value = false;
    const scrollY = window.scrollY;
    headerVisible.value = scrollY <= 0;
  } else {
    headerVisible.value = true;
  }
};

const changeLanguage = (event) => {
  locale.value = event.target.value;
};

const isBussiness = computed(() => {
  if (route.path.includes("/business")) {
    return true;
  } else {
    return false;
  }
});
const isMenuOpen = ref(false);

const isFormOpen = computed({
  get: () => { return route.query?.isFormOpen === 'true' },
  set: async (value) => { await navigateTo(`${route.path}?isFormOpen=${value}`) },
})

function onMenuClick() {
  if(isFormOpen.value) {
    isFormOpen.value = false
    isMenuOpen.value = false;
    return
  }
  if (!isMenuOpen.value) {
    isMenuOpen.value = true;
  } else {
    isMenuOpen.value = false;
  }
}
const activeTab = ref("about");

function onNavClick(tab) {
  activeTab.value = tab;
  if (width.value > 1280) isMenuOpen.value = false;
}
</script>

<template>
  <Body
    :class="isMenuOpen ? 'max-h-screen max-w-screen overflow-hidden' : ''"
  ></Body>
  <div class="hidden lg:block">
    <div
      :class="{ block: headerVisible, hidden: !headerVisible }"
      class="bg-[#F2F2F7]"
    >
      <div class="container">
        <div class="">
          <div class="flex justify-between py-4 items-center">
            <nuxt-link
              :to="localePath('/')"
              :class="{ 'active-link_': isActive(localePath('/')) }"
              >{{ t("Частным клиентам") }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('/business')"
              :class="{ 'active-link_': isActive(localePath('/business')) }"
              >{{ t("Бизнесу") }}</nuxt-link
            >
            <hr class="w-[1px] h-6 bg-[#333333]" />
            <nuxt-link
              :to="localePath('/about')"
              :class="{ 'active-link_': isActive(localePath('/about')) }"
              >{{ t("О банке") }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('/news')"
              :class="{ 'active-link_': isActive(localePath('/news')) }"
              >{{ t("Новости и статьи") }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('/offices')"
              :class="{ 'active-link_': isActive(localePath('/offices')) }"
              >{{ t("Офисы и банкоматы") }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('/vacancies')"
              :class="{ 'active-link_': isActive(localePath('/vacancies')) }"
              >{{ t("Вакансии") }}</nuxt-link
            >
            <div class="relative">
              <AInput
                width="regular"
                styleInput="header"
                class="rounded-full bg-[#F2F2F7]"
                placeholder="Поиск продукта или услуги..."
              />
              <a-icon-loop class="absolute top-3 right-3 h-5 w-5" />
            </div>
            <!-- <a-icon-rus></a-icon-rus>
       <a-icon-Langesp></a-icon-Langesp> -->
            <ALangSelector></ALangSelector>

            <!-- <select id="language" name="language" class="bg-[#F2F2F7] border-none " @change="changeLanguage">
              <option value="ru">Рус</option>
              <option value="en">Eng</option>
            </select> -->
          </div>
        </div>
      </div>
    </div>
    <div class="shadow-input w-full bg-white sticky top-0 px-4 z-50">
      <div class="container">
        <div
          v-if="!isBussiness"
          class="flex justify-between py-10 items-center relative"
        >
          <nuxt-link :to="localePath('/')">
            <a-icon-logo></a-icon-logo
          ></nuxt-link>
          <nuxt-link
            :to="localePath('/deposits')"
            :class="{ 'active-link': isActive(localePath('/deposits')) }"
            >{{ t("Вклады") }}</nuxt-link
          >
          <nuxt-link
            :to="localePath('/cards')"
            :class="{ 'active-link': isActive(localePath('/cards')) }"
            >{{ t("Карты") }}</nuxt-link
          >
          <nuxt-link
            :to="localePath('/safeboxes')"
            :class="{ 'active-link': isActive(localePath('/safeboxes')) }"
            >{{ t("Сейфовые ячейки") }}</nuxt-link
          >
          <nuxt-link
            :to="localePath('/coins')"
            :class="{ 'active-link': isActive(localePath('/coins')) }"
            >{{ t("АПСАРЫ") }}</nuxt-link
          >
          <!-- <div class="flex gap-1 items-center">
            <a-icon-exclamation></a-icon-exclamation>
            <nuxt-link
              :to="localePath('/payments')"
              :class="{ 'active-link': isActive(localePath('/payments')) }"
              >{{ t("Переводы") }}</nuxt-link
            >
          </div> -->
          <a-button
            @click="toggleHelp"
            type="yellow"
            font-size="lg"
            width="medium"
            >{{ t("Онлайн-банк") }}</a-button
          >
          <div
            v-if="showHelp"
            class="absolute rounded-[20px] bg-[#ffffff] border-[#E3E3E3] border top-24 p-4 right-0 w-[190px]"
          >
            <div class="grid gap-3">
              <a href="https://ibank.amra-bank.com/web_banking/protected/welcome.jsf">{{t('Частным клиентам')}}</a>
              <a href="https://ibank.amra-bank.com/ibank2/#/">{{t('Бизнесу')}}</a>
              <a href="http://amra-wallet.com/">{{t('Кошелек')}}</a>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-between py-10 items-center">
          <nuxt-link :to="localePath('/')">
            <a-icon-logo></a-icon-logo
          ></nuxt-link>
          <nuxt-link
            :to="localePath('/business#acquirer')"
            :class="{
              'active-link': isActive(localePath('/business#acquirer')),
            }"
            >{{ t("Эквайринг") }}</nuxt-link
          >
          <nuxt-link
            :to="localePath('/business#payroll')"
            :class="{
              'active-link': isActive(localePath('/business#payroll')),
            }"
            >{{ t("Зарплатный проект") }}</nuxt-link
          >
          <nuxt-link
            :to="localePath('/business#business')"
            :class="{
              'active-link': isActive(localePath('/business#business')),
            }"
            >{{ t("Кредитование") }}</nuxt-link
          >
          <!-- <div class="flex gap-1 items-center">
            <a-icon-exclamation></a-icon-exclamation>
            <nuxt-link
              :to="localePath('/payments')"
              :class="{ 'active-link': isActive(localePath('/payments')) }"
              >{{ t("Переводы") }}</nuxt-link
            >
          </div> -->
          <a-button type="yellow" font-size="lg" width="medium">{{
            t("Онлайн-банк")
          }}</a-button>
        </div>
      </div>
    </div>
  </div>
  <div class="lg:hidden block relative z-10 " :class="isMenuOpen ? 'menu-fullscreen' : ''">
    <div class="h-[50px] bg-[#FFED00] py-1 shadow-input">
      <div class="px-4">
        <div class="flex justify-between items-center">
          <nuxt-link :to="localePath('/')">
            <a-icon-logo-mobile></a-icon-logo-mobile>
          </nuxt-link>
          <div>
            <button
              class="h-6 w-6 border-none focus:outline-none flex flex-col justify-between menu-button"
              :class="isMenuOpen ? 'is-open' : ''"
              @click="onMenuClick"
            >
              <hr
                class="w-full border-0 bg-[#333333] rounded-sm transform transition-all ease-linear duration-150"
              />
              <hr
                class="w-full border-0 bg-[#333333] rounded-sm transform transition-all ease-linear duration-150"
              />
              <hr
                class="w-full border-0 bg-[#333333] rounded-sm transform transition-all ease-linear duration-150"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" class="h-full">
      <div class="container lg:px-0 px-4">
        <div class="items-center mt-[6px] grid grid-cols-12 gap-[9px]">
          <div class="relative col-span-10">
            <AInput
              styleInput="simple"
              rounded="xl"
              :placeholder="t('Поиск...')"
            />
            <a-icon-loop
              class="absolute inset-y-0 right-0 h-[18px] w-[18px] mr-3 mt-3"
            />
          </div>
          <div class="col-span-1 flex">
            <a-icon-rus></a-icon-rus>
            <ALangSelector></ALangSelector>
          </div>
        </div>
        <div class="grid gap-2 mt-3 relative">
          <nuxt-link :to="localePath('/online24')" class="flex gap-3 items-center">
            <a-icon-entry></a-icon-entry>
            <p>{{ t("Вход в онлайн-банк") }}</p>
          </nuxt-link>
        
          <a-button
            @click="toggleHelp"
            type="yellow"
            font-size=""
            width="small"
          height="sm"
            >{{ t("Онлайн-банк") }}</a-button
          >
          <div
            v-if="showHelp"
            class="absolute rounded-[20px] bg-[#ffffff] border-[#E3E3E3] border top-0 p-4 right-0 w-[190px]"
          >
            <div class="grid gap-3">
              <a href="https://ibank.amra-bank.com/web_banking/protected/welcome.jsf">{{t('Частным клиентам')}}</a>
              <a href="https://ibank.amra-bank.com/ibank2/#/">{{t('Бизнесу')}}</a>
              <a href="http://amra-wallet.com/">{{t('Кошелек')}}</a>
            </div>
          </div>
          <!-- <div
            v-if="showHelp"
            class="absolute rounded-[20px] bg-[#ffffff] border-[#E3E3E3] border top-6 p-4 left-0 w-[190px]"
          >
            <div class="grid gap-3">
              <a href="https://ibank.amra-bank.com/web_banking/protected/welcome.jsf">{{t('Частным клиентам')}}</a>
              <a href="https://ibank.amra-bank.com/ibank2/#/">{{t('Бизнесу')}}</a>
              <a href="http://amra-wallet.com/">{{t('Кошелек')}}</a>
            </div>
          </div> -->
          <ABankChat></ABankChat>
          <!-- <nuxt-link
            :to="localePath('/payments')"
            class="flex gap-3 items-center"
          >
            <div class="w-6 flex justify-end">
              <div
                class="w-[18px] h-[18px] border-2 border-[#6F6F6F] rounded-full flex justify-center"
              >
                <a-icon-exclamation
                  class="h-[14px] pt-[1px]"
                ></a-icon-exclamation>
              </div>
            </div>
            <p>{{ t("Переводы") }}</p>
          </nuxt-link> -->
        </div>
      </div>
      <div class="bg-[#FAFAFA]">
        <div
          class="mt-[10px] h-[34px] flex justify-between font-firasans text-base container"
        >
          <button
            :class="[activeTab === 'about' ? 'bg-darkYellow ' : '']"
            type="menu"
            class="!rounded-l-none !rounded-[10px] !h-[34px] !text-base px-4"
            @click="onNavClick('about')"
          >
            {{ t("О банке") }}
          </button>
          <button
            :class="[activeTab === 'private' ? 'bg-darkYellow' : '']"
            type="menu"
            class="px-[6px] !rounded-[10px] !h-[34px] !text-base"
            @click="onNavClick('private')"
          >
            {{ t("Частным клиентам") }}
          </button>
          <button
            :class="[activeTab === 'business' ? 'bg-darkYellow' : '']"
            type="menu"
            class="!rounded-r-none !rounded-[10px] !text-base !h-[34px] px-4"
            @click="onNavClick('business')"
          >
            {{ t("Бизнесу") }}
          </button>
        </div>
      </div>
      <div class="">
        <div class="lg:px-0 px-4 container" v-if="activeTab === 'about'">
          <nuxt-link
            :to="localePath('/about')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("О банке") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/news')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Новости и статьи") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/offices')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Офисы и банкоматы") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/vacancies')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Вакансии") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <a-icon-progress2
            class="w-full h-full place-content-center"
          ></a-icon-progress2>
        </div>
        <div class="lg:px-0 px-4 container" v-if="activeTab === 'private'">
          <nuxt-link
            :to="localePath('/deposits')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Вклады") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/cards')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Карты") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/safeboxes')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("Сейфовые ячейки") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <nuxt-link
            :to="localePath('/coins')"
            class="flex justify-between items-center"
          >
            <p class="py-2">{{ t("АПСАРЫ") }}</p>
            <a-icon-arrow-mobile></a-icon-arrow-mobile>
          </nuxt-link>
          <a-icon-depositor
            class="w-full h-full place-content-center"
          ></a-icon-depositor>
        </div>
        <div class="grid h-full" v-if="activeTab === 'business'">
          <div class="lg:px-0 px-4 container">
            <nuxt-link
              :to="localePath('/business')"
              class="flex justify-between items-center"
            >
              <p class="py-2">{{ t("Эквайринг") }}</p>
              <a-icon-arrow-mobile></a-icon-arrow-mobile>
            </nuxt-link>
            <nuxt-link
              :to="localePath('/business')"
              class="flex justify-between items-center"
            >
              <p class="py-2">{{ t("Зарплатный проект") }}</p>
              <a-icon-arrow-mobile></a-icon-arrow-mobile>
            </nuxt-link>
            <nuxt-link
              :to="localePath('/business')"
              class="flex justify-between items-center"
            >
              <p class="py-2">{{ t("Кредитование") }}</p>
              <a-icon-arrow-mobile></a-icon-arrow-mobile>
            </nuxt-link>
          </div>
          <a-icon-slide-acquiring
            class="w-full h-full place-content-center"
          ></a-icon-slide-acquiring>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.active-link {
  position: relative;
}

.active-link::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: calc(100%);
  background-color: #ffeb3b;
  border-radius: 5px;
}

.active-link_ {
  position: relative;
}

.active-link_::after {
  content: "";
  position: absolute;
  bottom: -23px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: calc(100%);
  background-color: #333333;
  border-radius: 5px;
}

.menu-button {
  position: relative;

  hr {
    position: absolute;
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    opacity: 1;
  }

  hr:nth-child(1) {
    top: 2px;
  }

  hr:nth-child(2) {
    top: 10px;
  }

  hr:nth-child(3) {
    top: 18px;
  }
}

.menu-button.is-open {
  hr:nth-child(1) {
    transform: rotate(45deg) !important;
    top: 11px;
  }

  hr:nth-child(2) {
    opacity: 0;
  }

  hr:nth-child(3) {
    transform: rotate(-45deg) !important;
    top: 11px;
  }
}

.menu-fullscreen {
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 100;
}
</style>
